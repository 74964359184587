import { Control } from '@yleisradio/areena-types';
import React from 'react';
import {
  DSLink,
  LinkIconPosition,
  LinkTextSize,
  LinkTheme,
  LinkWeightName,
} from './DSLink';
import logger from 'services/logger';

type Props = {
  control: Control;
  iconPosition?: LinkIconPosition | undefined;
  textSize?: LinkTextSize | undefined;
  weightName?: LinkWeightName | undefined;
  onClick?: (() => void) | undefined;
  theme?: LinkTheme | undefined;
  dataTestId?: string | undefined;
};

export const ControlLink: React.FC<Props> = ({
  control,
  iconPosition,
  onClick,
  textSize,
  weightName,
  theme,
  dataTestId,
}) => {
  if (!control.destination) {
    logger.warn('Link control is missing destination');
    return null;
  }

  return (
    <DSLink
      iconPosition={iconPosition}
      onClick={onClick}
      pointer={control.destination}
      text={control.title}
      textSize={textSize}
      weightName={weightName}
      theme={theme}
      data-testid={dataTestId}
    />
  );
};
