import {
  Analytics,
  Card,
  Control,
  Player,
  Pointer,
} from '@yleisradio/areena-types';
import { toast, toastPlaybackError } from 'components/Notifications';
import { playerToast } from 'components/Player/AudioPlayerWrapper/PlayerToast/PlayerToast';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useShowRecommendationsQueue } from 'contexts/PlayerStateContext/useShowRecommendationsQueue';
import { useProgressCacheInvalidator } from 'contexts/ProgressCacheInvalidatorContext';
import { useCards } from 'hooks/useCards';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getAvailablePlayerByProgramId } from 'services/areena-api/fetchers';
import logger from 'services/logger';
import { getTranslation } from 'services/translations';
import { Labels, trackEvent } from 'services/yleAnalyticsSdk';
import { idFromPointer } from 'utils/pointer';
import { getItemIdFromClientPath } from 'utils/url';
import { useTunnusContext } from '../TunnusContext';
import { QueueItem } from './QueueItem';
import { useQueueState } from './useQueueState';
import { useEscHandler } from 'contexts/EscHandlerContext/EscHandlerContext';

export const QUEUE_PAGE_SIZE = 5;

// Restore podcast from local storage
// New values are JSON strings (id of the program) but old implementation stored player objects
function usePodcastToRestore(): [
  id: string | null,
  setId: (newId: string | undefined) => void,
] {
  const [podcastToRestore, setPodcastToRestore] = useLocalStorage<
    string | Player | undefined
  >('podcasts:podcastToRestore', undefined);

  let programId: string | null = null;

  try {
    if (typeof podcastToRestore === 'string') {
      programId = podcastToRestore;
    }

    // Old implementation stored player objects
    if (typeof podcastToRestore === 'object' && podcastToRestore !== null) {
      const player = podcastToRestore as Player;
      programId = player.item.id;
    }
  } catch (e) {
    logger.error('Error restoring podcast', e);
  }

  return [programId, setPodcastToRestore];
}

export type ActivePlayer = Player & {
  preventAutoPlay: boolean;
};

type Value = {
  readonly activePlayer: ActivePlayer | undefined;
  readonly setActivePlayer: (player: Player | undefined) => void;
  readonly queue: QueueItem[];
  readonly setQueue: (items: QueueItem[]) => void;
  readonly currentlyPlayingId: string | undefined;
  readonly togglePointerInQueue: (control: Control) => void;
  readonly hasNext: boolean;
  readonly hasPrevious: boolean;
  readonly handleOnPlayNext: () => void;
  readonly handleOnPlayPrev: () => void;
  readonly isPointerTargetInQueue: (pointer?: Pointer) => boolean;
  readonly isPlaying: boolean;
  readonly setIsPlaying: (isPlaying: boolean) => void;
  readonly setEntryItemId: (itemId: string) => void;
  readonly setPodcastToRestore: (itemId: string) => void;
  readonly clearPodcastToRestore: () => void;
  readonly closePopover: () => void;
  readonly isPopoverOpen: boolean;
  readonly togglePopover: () => void;
  readonly recommendationsQueue: Card[];
  readonly recommendationsAnalyticsContext: Labels | undefined;
  readonly updateRecommendationsSource: (player: Player | undefined) => void;
  readonly showRecommendationsQueue: boolean;
  readonly setShowRecommendationsQueue: (value: boolean) => void;
};

// Not exported on purpose, use Provider component and hook below
const PlayerStateContext = React.createContext<Value>({
  activePlayer: undefined,
  setActivePlayer: () => {
    logger.warn("ActivePlayerContext doesn't have a Provider");
  },
  queue: [],
  setQueue: () => {
    logger.warn(
      "Can'set items in queue because ActivePlayerContext doesn't have a Provider"
    );
    return undefined;
  },
  currentlyPlayingId: undefined,
  togglePointerInQueue: () => {
    logger.warn(
      "Can't toggle program to queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  hasNext: false,
  hasPrevious: false,
  handleOnPlayNext: () => {
    logger.warn(
      "Can't get next in queue because ActivePlayerContext doesn't have a Provider"
    );
    return undefined;
  },
  handleOnPlayPrev: () => {
    logger.warn(
      "Can't get previous in queue because ActivePlayerContext doesn't have a Provider"
    );
    return undefined;
  },
  isPointerTargetInQueue: () => {
    logger.warn(
      "Can't get item in queue because ActivePlayerContext doesn't have a Provider"
    );
    return false;
  },
  isPopoverOpen: false,
  closePopover: () => {
    logger.warn(
      "Can't close popOver because ActivePlayerContext doesn't have a Provider"
    );
    return undefined;
  },
  togglePopover: () => {
    logger.warn(
      "Can't toggle popOver becayse ActivePlayerContext doesn't have a Provider"
    );
    return undefined;
  },
  isPlaying: false,
  setIsPlaying() {
    logger.warn(
      "Can't get item in queue because ActivePlayerContext doesn't have a Provider"
    );
  },
  setEntryItemId() {
    logger.warn(
      "Can't set entry item id because ActivePlayerContext does not have a Provider"
    );
  },
  setPodcastToRestore() {
    logger.warn(
      "Can't update podcast to restore without Provider of ActivePlayerContext"
    );
  },
  clearPodcastToRestore() {
    logger.warn(
      "Can't clear podcast to restore without Provider of the ActivePlayerContext"
    );
  },
  recommendationsQueue: [],
  recommendationsAnalyticsContext: undefined,
  updateRecommendationsSource() {
    logger.warn(
      "Can't set recommendations source without Provider of the ActivePlayerContext"
    );
  },
  showRecommendationsQueue: true,
  setShowRecommendationsQueue() {
    logger.warn(
      "Can't set show recommendations queue without Provider of the ActivePlayerContext"
    );
  },
});

export const PlayerStateProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activePlayer, setActivePlayerState] = useState<
    ActivePlayer | undefined
  >();
  const [podcastToRestore, savePodcastToRestore] = usePodcastToRestore();
  const [entryItemId, setEntryItemId] = useState<string | undefined>();
  const [currentlyPlaying, setCurrentlyPlaying] = useState<
    { id: string; isFromDefaultQueue: boolean } | undefined
  >();
  const [isPlaying, setIsPlaying] = useState(false);
  const language = useUILanguage();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [queue, setQueue] = useQueueState();

  const [queueSeries, setSeries] = useState<string[]>([]);
  const [recommendationsQueue, setRecommendationsQueue] = useState<Card[]>([]);

  const [historyIds, setHistoryIds] = useState<string[]>([]);

  const [recommendationsSource, setRecommendationsSource] = useState<
    Pointer | undefined
  >(undefined);

  const { invalidate: invalidateUserProgress } = useProgressCacheInvalidator();

  const { isAuthenticated } = useTunnusContext();
  const t = useTranslation();

  const [showRecommendationsQueue, setShowRecommendationsQueue] =
    useShowRecommendationsQueue();

  const updateRecommendationsSource = useCallback(
    (player: Player | undefined) => {
      // Don't load recommendations for unauthenticated user: user has no way of seeing recommendations unless logged in.
      if (!isAuthenticated) return;

      if (!player) return;

      // Don't update the recommendations list if user clicked item which is already on the recommendations list.
      const isFromRecommendationsList = recommendationsQueue.some(
        (card) => idFromPointer(card.pointer) === player.item.id
      );

      if (isFromRecommendationsList) return;

      setRecommendationsSource(player.recommendationsSource);
    },
    [recommendationsQueue, isAuthenticated]
  );

  const { cards: cardsSeries } = useCards({
    source: activePlayer?.playlistSource,
    pageIndex: 0,
    pageSize: QUEUE_PAGE_SIZE,
  });

  const {
    cards: cardsRecommended,
    analyticsContext: recommendationsAnalyticsContext,
  } = useCards({
    source: recommendationsSource,
    pageIndex: 0,
    pageSize: QUEUE_PAGE_SIZE,
  });

  useEffect(() => {
    setSeries(
      cardsSeries
        .map((c) => idFromPointer(c.pointer))
        .filter((id): id is string => id !== undefined)
    );

    return () => setSeries([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlayer, cardsSeries.length]);

  useEffect(() => {
    setRecommendationsQueue(cardsRecommended);

    return () => setRecommendationsQueue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendationsSource, cardsRecommended.length]);

  const { areenaService } = useAreenaService();
  const route = useRouter();

  // Restore podcast from local storage
  useEffect(() => {
    (async () => {
      const hasIdInPath = getItemIdFromClientPath(route.asPath);
      if (
        areenaService === 'radio' &&
        podcastToRestore &&
        !activePlayer &&
        !hasIdInPath
      ) {
        const newPlayer = await getAvailablePlayerByProgramId(
          podcastToRestore,
          language
        );

        if (newPlayer) {
          setActivePlayerState({
            ...newPlayer,
            preventAutoPlay: true,
          });
          setCurrentlyPlaying({
            id: newPlayer.item.id,
            isFromDefaultQueue: false,
          });
        } else {
          logger.debug(
            `No available player for id ${podcastToRestore}, removing it from local storage`
          );
          savePodcastToRestore(undefined);
        }
      }
    })();
  }, [
    areenaService,
    podcastToRestore,
    activePlayer,
    language,
    savePodcastToRestore,
    route.asPath,
  ]);

  const { addEventHandler, removeEventHandler } = useEscHandler();
  const eventHandlerTagPopover = 'popover';

  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
    removeEventHandler(eventHandlerTagPopover);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePopover = useCallback(() => {
    if (!isPopoverOpen) {
      addEventHandler(closePopover, eventHandlerTagPopover);
    } else {
      removeEventHandler(eventHandlerTagPopover);
    }
    setIsPopoverOpen((isOpen) => !isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopover, isPopoverOpen]);

  const addCurrentItemToHistory = useCallback(() => {
    if (currentlyPlaying && !historyIds.includes(currentlyPlaying.id)) {
      setHistoryIds((prevState) => [currentlyPlaying.id, ...prevState]);
    }
  }, [currentlyPlaying, historyIds]);

  const popFirstItemFromHistory = useCallback(() => {
    const firstItem = historyIds[0];
    setHistoryIds((prevState) => prevState.slice(1));
    return firstItem;
  }, [historyIds]);

  const eventHandlerTagPlayer = 'player';

  const setActivePlayer = useCallback(
    (player: Player | undefined, isFromDefaultQueue = false) => {
      // Re-fetch user progress in all lists when the player is closed
      if (!player) {
        invalidateUserProgress();
        setCurrentlyPlaying(undefined);
        setIsPlaying(false);
        setActivePlayerState(undefined);
        removeEventHandler(eventHandlerTagPlayer);
        return;
      }

      if (player.media.type === 'AudioObject') {
        setCurrentlyPlaying({ id: player.item.id, isFromDefaultQueue });
        setIsPlaying(true);
      }

      setActivePlayerState(
        player ? { ...player, preventAutoPlay: false } : player
      );
      addEventHandler(
        () => setActivePlayerState(undefined),
        eventHandlerTagPlayer
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invalidateUserProgress, setActivePlayerState]
  );

  const isPointerTargetInQueue = useCallback(
    (pointer?: Pointer) => {
      const id = idFromPointer(pointer);
      if (!id) return false;

      return queue.some((item) => item.programId === id);
    },
    [queue]
  );

  const fetchPlayer = useCallback(
    async (id: string) => {
      try {
        const playerRes = await getAvailablePlayerByProgramId(id, language);
        if (!playerRes) {
          logger.debug(
            `No available player for program with id ${id}, closing player`
          );
          toastPlaybackError(language);
        }
        return playerRes || undefined;
      } catch (e) {
        setActivePlayer(undefined);
        logger.error('Error fetching player ', e);
        toastPlaybackError(language);
      }
    },
    [language, setActivePlayer]
  );

  const toggleIdInQueue = useCallback(
    async (id: string, analytics?: Analytics) => {
      // Open player if user is adding their first item to queue list and player is closed.
      if (queue.length === 0 && activePlayer === undefined) {
        trackEvent('areena.play-queue.add', null, analytics?.context?.comscore);
        void playerToast(t('addedToQueue'));

        const playerRes = await fetchPlayer(id);
        if (playerRes !== undefined) {
          setCurrentlyPlaying({ id, isFromDefaultQueue: false });
          setIsPlaying(false);
          setActivePlayerState({
            ...playerRes,
            preventAutoPlay: true,
          });
        }
        // Don't add the freshly fetched item to queue
        return;
      }

      if (queue.some((item) => item.programId === id)) {
        trackEvent(
          'areena.play-queue.remove',
          null,
          analytics?.context?.comscore
        );
        playerToast(t('removedFromQueue'));
        setQueue(queue.filter((item) => item.programId !== id));
      } else {
        trackEvent('areena.play-queue.add', null, analytics?.context?.comscore);
        playerToast(t('addedToQueue'));
        setQueue([...queue, { key: `${Date.now()}-${id}`, programId: id }]);
      }
    },
    [setQueue, activePlayer, queue, fetchPlayer, t]
  );

  const togglePointerInQueue = useCallback(
    (control?: Control) => {
      const id = idFromPointer(control?.destination);

      if (!id) {
        void toast(
          getTranslation('queueToggleError', language),
          'error',
          getTranslation('notificationHelpTextGeneric', language)
        );

        throw new Error(
          "Couldn't get id from pointer when trying to toggle item in queue"
        );
      }
      void toggleIdInQueue(id, control?.analytics);
    },
    [toggleIdInQueue, language]
  );

  const addIdToFirstInQueue = useCallback(
    (itemId: string) => {
      if (queue[0]?.programId !== itemId) {
        setQueue([
          { key: `${Date.now()}-${itemId}`, programId: itemId },
          ...queue,
        ]);
      }
    },
    [queue, setQueue]
  );

  const hasNext =
    queue.length > 0 ||
    queueSeries.length > 0 ||
    (recommendationsQueue.length > 0 && showRecommendationsQueue);

  const hasPrevious =
    currentlyPlaying?.id !== entryItemId && historyIds.length > 0;

  const popNextId = useCallback((): string | undefined => {
    if (queue[0]) {
      setQueue(queue.slice(1));
      return queue[0].programId;
    }
    if (queueSeries[0]) {
      return queueSeries[0];
    }
    if (recommendationsQueue[0]) {
      if (!showRecommendationsQueue) {
        logger.debug(
          `User has set recommendations queue inactive, not playing recommendations.`
        );
        return;
      }
      setRecommendationsQueue((prevState) => prevState.slice(1));
      return idFromPointer(recommendationsQueue[0].pointer) || undefined;
    }
  }, [
    queue,
    recommendationsQueue,
    showRecommendationsQueue,
    queueSeries,
    setQueue,
  ]);

  const handleOnPlayNext = useCallback(async () => {
    const nextId = popNextId();

    if (!nextId) return;

    const playerRes = await fetchPlayer(nextId);

    if (playerRes !== undefined) {
      const isFromUserQueue = Boolean(queue.length);

      setActivePlayer(playerRes, !isFromUserQueue);

      addCurrentItemToHistory();
    }
  }, [
    popNextId,
    fetchPlayer,
    queue.length,
    setActivePlayer,
    addCurrentItemToHistory,
  ]);

  const handleOnPlayPrev = useCallback(async () => {
    if (currentlyPlaying?.id === entryItemId) return;

    const prevId = popFirstItemFromHistory();

    if (!prevId) return;

    const playerRes = await fetchPlayer(prevId);

    if (playerRes !== undefined) {
      setActivePlayer(playerRes);

      if (
        currentlyPlaying !== undefined &&
        !currentlyPlaying.isFromDefaultQueue
      ) {
        addIdToFirstInQueue(currentlyPlaying.id);
      }

      if (currentlyPlaying?.isFromDefaultQueue) {
        updateRecommendationsSource(playerRes);
      }
    }
  }, [
    fetchPlayer,
    setActivePlayer,
    currentlyPlaying,
    entryItemId,
    addIdToFirstInQueue,
    popFirstItemFromHistory,
    updateRecommendationsSource,
  ]);

  const setPodcastToRestore = useCallback(
    (id: string) => {
      if (areenaService !== 'radio') return;

      savePodcastToRestore(id);
    },
    [areenaService, savePodcastToRestore]
  );

  const clearPodcastToRestore = useCallback(() => {
    if (areenaService !== 'radio') return;

    savePodcastToRestore(undefined);
  }, [areenaService, savePodcastToRestore]);

  const value = useMemo<Value>(
    () => ({
      activePlayer,
      setActivePlayer,
      queue,
      setQueue,
      currentlyPlayingId: currentlyPlaying?.id,
      togglePointerInQueue,
      hasNext,
      hasPrevious,
      handleOnPlayNext,
      handleOnPlayPrev,
      isPointerTargetInQueue,
      isPlaying,
      setIsPlaying,
      setEntryItemId,
      setPodcastToRestore,
      clearPodcastToRestore,
      closePopover,
      isPopoverOpen,
      togglePopover,
      recommendationsQueue,
      recommendationsAnalyticsContext,
      updateRecommendationsSource,
      showRecommendationsQueue,
      setShowRecommendationsQueue,
    }),
    [
      activePlayer,
      setActivePlayer,
      queue,
      setQueue,
      currentlyPlaying,
      togglePointerInQueue,
      hasNext,
      hasPrevious,
      handleOnPlayNext,
      handleOnPlayPrev,
      isPointerTargetInQueue,
      isPlaying,
      setPodcastToRestore,
      clearPodcastToRestore,
      closePopover,
      isPopoverOpen,
      togglePopover,
      recommendationsQueue,
      recommendationsAnalyticsContext,
      updateRecommendationsSource,
      showRecommendationsQueue,
      setShowRecommendationsQueue,
    ]
  );

  return (
    <PlayerStateContext.Provider value={value}>
      {children}
    </PlayerStateContext.Provider>
  );
};

export function usePlayerState(): Value {
  return useContext(PlayerStateContext);
}
