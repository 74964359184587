import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import YleAreenaLogo from 'assets/areena.svg';
import YleArenanLogo from 'assets/arenan.svg';
import UserIcon from 'assets/user.svg';
import CloseIcon from 'assets/close.svg';
import { Link } from 'components/Link';
import styles from './Header.module.scss';
import { UserDropdown } from './UserDropdown';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useLoginAction } from 'components/Controls/Control/actions/useLoginAction';
import { NavigationLink } from 'components/Navigation/NavigationLink';
import { VerticalDivider } from 'components/Divider/VerticalDivider';
import { useTranslation } from 'hooks/useTranslation';
import {
  NavigationService,
  navigationServiceFromClientPath,
} from 'components/Navigation/navigationService';
import TvIcon from 'assets/tv.svg';
import TvIconActive from 'assets/tv-active.svg';
import HeadphonesIcon from 'assets/headphones.svg';
import HeadphonesIconActive from 'assets/headphones-active.svg';
import LiveStreamingIcon from 'assets/live-streaming.svg';
import LiveStreamingIconActive from 'assets/live-streaming-active.svg';
import SearchIcon from 'assets/search.svg';
import SearchIconActive from 'assets/search-active.svg';
import { useCustomEventAnalytics } from 'hooks/analytics';
import { useUILanguage } from 'hooks/useUILanguage';
import { serviceRoutes } from 'utils/routes';
import { SearchBar } from 'components/SearchBar/SearchBar';
import classNames from 'classnames';
import { MegaMenu, MENU_ID } from 'components/Navigation/MegaMenu/MegaMenu';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useRouter } from 'next/router';
import { LocationContextProvider } from 'contexts/LocationContext';
import { BrowseMenuContent } from '@yleisradio/areena-types/domain/v2/browseMenuContent';
import { useEscHandler } from 'contexts/EscHandlerContext/EscHandlerContext';

const CloseIconAndTextButton: FC<{ closeMenu(): void }> = ({ closeMenu }) => {
  const t = useTranslation();

  const textStyle = classNames(styles.textStyles, styles.toggleMenuText);

  return (
    <button
      className={styles.buttonContainer}
      onClick={closeMenu}
      aria-controls={MENU_ID}
      aria-expanded={true}
    >
      <div className={textStyle}>{t('close')}</div>
      <CloseIcon className={styles.icon} aria-hidden />
    </button>
  );
};

const SearchIconAndTextButton: FC<{
  isActive: boolean;
  openMenu(): void;
}> = ({ isActive, openMenu }) => {
  const trackEvent = useCustomEventAnalytics();
  const t = useTranslation();
  const iconClassNames = classNames(
    styles.icon,
    isActive && styles.searchActive
  );
  const textClassNames = classNames(
    styles.textStyles,
    styles.toggleMenuText,
    isActive && styles.searchActive
  );
  const searchButtonClassNames = classNames(
    styles.buttonContainer,
    styles.searchButton
  );

  return (
    <button
      className={searchButtonClassNames}
      onClick={() => {
        trackEvent('header-searchfield-click');
        openMenu();
      }}
      aria-controls={MENU_ID}
      aria-expanded={false}
    >
      {isActive ? (
        <SearchIconActive className={iconClassNames} aria-hidden />
      ) : (
        <SearchIcon className={styles.icon} aria-hidden />
      )}
      <div className={classNames(textClassNames)}>{t('searchAndBrowse')}</div>
    </button>
  );
};

const AreenaLink: FC<{
  activeNavigationService: NavigationService | undefined;
  onClick(): void;
}> = ({ activeNavigationService, onClick }) => {
  const t = useTranslation();
  const language = useUILanguage();
  const to =
    activeNavigationService === 'radio'
      ? serviceRoutes['radio'][language]
      : serviceRoutes['tv'][language];

  return (
    <Link
      pointer={{ uri: `/${to}` }}
      aria-label={t('areena')}
      className={styles.areenaLogoLink}
      onClick={onClick}
    >
      {language === 'fi' ? (
        <YleAreenaLogo className={styles.areenaLogo} />
      ) : (
        <YleArenanLogo className={styles.areenaLogo} />
      )}
    </Link>
  );
};

type UserButtonProps = { userDropdown: React.ReactElement };

const UserButton: FC<UserButtonProps> = ({ userDropdown }) => {
  const trackEvent = useCustomEventAnalytics();
  const t = useTranslation();
  const { isAuthenticated } = useTunnusContext();
  const loginAction = useLoginAction();

  const handleOnClickUser = () => {
    trackEvent('header-tunnus-login-click');
    loginAction?.runAction();
  };

  return isAuthenticated ? (
    userDropdown
  ) : (
    <button
      className={styles.buttonContainer}
      onClick={handleOnClickUser}
      data-testid="header-login"
    >
      <div className={classNames(styles.textStyles, styles.logInText)}>
        {t('logIn')}
      </div>
      <UserIcon className={styles.icon} aria-hidden />
    </button>
  );
};

type Props = {
  browseMenuContent: BrowseMenuContent | null;
};

const Header: FC<Props> = ({ browseMenuContent }) => {
  const t = useTranslation();
  const language = useUILanguage();
  const { asPath } = useRouter();
  const { areenaService } = useAreenaService();

  const activeNavigationService =
    navigationServiceFromClientPath(asPath) ?? areenaService;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const clearSearchQuery = () => setSearchQuery('');

  const { addEventHandler, removeEventHandler } = useEscHandler();
  const eventHandlerTag = 'mega-menu';

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    removeEventHandler(eventHandlerTag);
  }, [removeEventHandler]);

  const openMenu = () => {
    setIsMenuOpen(true);
    addEventHandler(closeMenu, eventHandlerTag);
  };

  const overlayClassNames = classNames(
    styles.contentOverlay,
    isMenuOpen && styles.visibleOverlay
  );

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMenuOpen) {
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  useEffect(() => {
    if (isMenuOpen && searchRef.current) {
      searchRef.current?.focus();
    }
  }, [isMenuOpen]);

  return (
    <nav
      aria-label={t('mainMenu')}
      className={styles.root}
      data-testid="app-navigation"
    >
      <LocationContextProvider>
        <div className={styles.mobile} data-testid="app-navigation-mobile">
          <div className={styles.headerTopBar}>
            {!isMenuOpen ? (
              <UserButton userDropdown={<UserDropdown breakpoint="mobile" />} />
            ) : (
              /* Placeholder for user button */
              <div className={styles.buttonContainer} aria-hidden />
            )}
            <AreenaLink
              activeNavigationService={activeNavigationService}
              onClick={closeMenu}
            />
            {isMenuOpen ? (
              <CloseIconAndTextButton closeMenu={closeMenu} />
            ) : (
              <SearchIconAndTextButton
                isActive={activeNavigationService === 'search'}
                openMenu={openMenu}
              />
            )}
          </div>
          {isMenuOpen ? (
            <>
              {/* Placeholder for keeping page content in place */}
              <div className={styles.headerBottomBar} aria-hidden />
              <div className={styles.searchBarContainerMobile}>
                <div className={styles.searchBarContainer}>
                  <SearchBar
                    placeholder={t('search')}
                    searchQuery={searchQuery}
                    setQuery={setSearchQuery}
                    inputId={'header-search-input-mobile'}
                    ref={searchRef}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.headerBottomBar}>
              <NavigationLink
                icon={TvIcon}
                activeIcon={TvIconActive}
                isActive={activeNavigationService === 'tv'}
                to={`/${serviceRoutes['tv'][language]}`}
              >
                {t('tv')}
              </NavigationLink>
              <NavigationLink
                icon={HeadphonesIcon}
                activeIcon={HeadphonesIconActive}
                isActive={activeNavigationService === 'radio'}
                to={`/${serviceRoutes['radio'][language]}`}
              >
                {t('podcasts')}
              </NavigationLink>
              <NavigationLink
                icon={LiveStreamingIcon}
                activeIcon={LiveStreamingIconActive}
                isActive={activeNavigationService === 'broadcasts'}
                to={`/${serviceRoutes['broadcasts'][language]}`}
              >
                {t('broadcasts')}
              </NavigationLink>
            </div>
          )}
        </div>
        <div className={styles.desktop} data-testid="app-navigation-desktop">
          <AreenaLink
            activeNavigationService={activeNavigationService}
            onClick={closeMenu}
          />
          {isMenuOpen ? (
            <>
              <div className={styles.searchBarContainer}>
                <SearchBar
                  placeholder={t('search')}
                  searchQuery={searchQuery}
                  setQuery={setSearchQuery}
                  inputId={'header-search-input-desktop'}
                  ref={searchRef}
                />
              </div>
              <div className={styles.closeContainer}>
                <CloseIconAndTextButton closeMenu={closeMenu} />
              </div>
            </>
          ) : (
            <>
              <div className={styles.desktopLeftContainer}>
                <NavigationLink
                  icon={TvIcon}
                  activeIcon={TvIconActive}
                  isActive={activeNavigationService === 'tv'}
                  to={`/${serviceRoutes['tv'][language]}`}
                >
                  {t('tv')}
                </NavigationLink>
                <NavigationLink
                  icon={HeadphonesIcon}
                  activeIcon={HeadphonesIconActive}
                  isActive={activeNavigationService === 'radio'}
                  to={`/${serviceRoutes['radio'][language]}`}
                >
                  {t('podcasts')}
                </NavigationLink>
                <NavigationLink
                  icon={LiveStreamingIcon}
                  activeIcon={LiveStreamingIconActive}
                  isActive={activeNavigationService === 'broadcasts'}
                  to={`/${serviceRoutes['broadcasts'][language]}`}
                >
                  {t('broadcasts')}
                </NavigationLink>
                <VerticalDivider />
                <SearchIconAndTextButton
                  isActive={activeNavigationService === 'search'}
                  openMenu={openMenu}
                />
              </div>
              <div className={styles.desktopRightContainer}>
                <VerticalDivider />
                <UserButton
                  userDropdown={<UserDropdown breakpoint="desktop" />}
                />
              </div>
            </>
          )}
        </div>
        {isMenuOpen && (
          <MegaMenu
            searchQuery={searchQuery}
            clearSearchQuery={clearSearchQuery}
            closeMenu={closeMenu}
            browseMenuContent={browseMenuContent}
          />
        )}
      </LocationContextProvider>
      {isMenuOpen && (
        <div
          className={overlayClassNames}
          onClick={closeMenu}
          aria-hidden
          tabIndex={-1}
        />
      )}
    </nav>
  );
};

export { Header };
