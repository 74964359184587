import { Pointer } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'components/Link';
import styles from './AlternateButton.module.scss';

type Size = 'small' | 'large';
type TextAlignment = 'left' | 'center';

interface AlternateButtonProps {
  pointer?: Pointer | undefined;
  text: string;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: React.MouseEventHandler | undefined;
  size?: Size;
  textAlignment?: TextAlignment;
  ariaLabel?: string;
  dataTestId?: string | undefined;
}

export const AlternateButton: React.FunctionComponent<AlternateButtonProps> = ({
  Icon,
  isDisabled,
  isSelected,
  onClick,
  pointer,
  text,
  size = 'small',
  textAlignment = 'left',
  ariaLabel,
  dataTestId,
}) => {
  const className = classNames(
    styles.root,
    Icon && styles.rootWithIcon,
    isDisabled && styles.rootDisabled,
    isSelected && styles.rootSelected,
    styles[size],
    styles[textAlignment]
  );
  const iconElement = Icon ? (
    <span className={styles.icon}>
      <Icon />
    </span>
  ) : null;

  if (pointer && !isDisabled) {
    return (
      <Link
        className={className}
        onClick={onClick}
        pointer={pointer}
        aria-label={ariaLabel}
        aria-current={isSelected}
        dataTestId={dataTestId}
      >
        {iconElement}
        {text}
      </Link>
    );
  }
  return (
    <button
      className={className}
      aria-label={ariaLabel}
      aria-current={isSelected}
      disabled={isDisabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {iconElement}
      {text}
    </button>
  );
};
