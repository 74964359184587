import React from 'react';
import { Pointer } from '@yleisradio/areena-types';
import { pointerToCanonicalURL } from 'utils/pointer';
import { useUILanguage } from 'hooks/useUILanguage';
import { useAreenaService } from 'contexts/AreenaServiceContext';

type Props = {
  itemIndex: number;
  pointer: Pointer | undefined;
  className?: string | undefined;
  hidden?: boolean | undefined;
};
export const ListItemMicrodata: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  itemIndex,
  hidden,
  pointer,
}) => {
  const language = useUILanguage();
  const { areenaService } = useAreenaService();
  const url =
    pointer && pointerToCanonicalURL(pointer, language, areenaService);

  if (!url) {
    return (
      <li hidden={hidden} className={className}>
        {children}
      </li>
    );
  }

  return (
    <li
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      hidden={hidden}
      className={className}
    >
      <meta itemProp="position" content={String(itemIndex + 1)} />
      <link itemProp="url" href={url} />
      {children}
    </li>
  );
};
