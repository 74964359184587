import { Pointer } from '@yleisradio/areena-types';
import ExternalLinkIcon from 'assets/externalLink.svg';
import classNames from 'classnames';
import { Link } from 'components/Link';
import React from 'react';
import styles from './DSLink.module.scss';

type PointerType = Pointer['type'];

const INTERNAL_POINTER_TYPES: ReadonlySet<PointerType> = new Set([
  'app',
  'clip',
  'package',
  'player',
  'program',
  'series',
  'service',
  'view',
  undefined,
] as PointerType[]);

function isExternal(pointer: Pointer): boolean {
  return !INTERNAL_POINTER_TYPES.has(pointer.type);
}

export type LinkIconPosition = 'left' | 'right';
export type LinkVariant = 'external' | 'internal';
export type LinkTextSize = 'xs' | 's' | 'm' | 'l' | 'xl';
export type LinkWeightName = 'regular' | 'bold';
export type LinkTheme = 'light';

type Props = {
  iconPosition?: LinkIconPosition | undefined;
  onClick?: (() => void) | undefined;
  pointer: Pointer | null;
  text: string | undefined;
  textSize?: LinkTextSize | undefined;
  weightName?: LinkWeightName | undefined;
  theme?: LinkTheme | undefined;
  linkVariant?: LinkVariant | undefined;
  dataTestId?: string | undefined;
};

/** Corresponds to Yle Design System's Link component */
export const DSLink: React.FC<Props> = ({
  iconPosition = 'left',
  onClick,
  pointer,
  text,
  textSize = 'm',
  weightName = 'regular',
  theme,
  linkVariant = 'external',
  dataTestId,
}) => {
  const className = classNames(
    styles.link,
    theme === 'light' && styles.linkLight,
    styles[`size-${textSize}`],
    styles[`weight-${weightName}`],
    !pointer && styles.button
  );

  if (!pointer) {
    return (
      <button className={className} onClick={onClick} data-testid={dataTestId}>
        <span>{text}</span>
      </button>
    );
  }

  const renderExternalIcon = isExternal(pointer) && linkVariant !== 'internal';
  const icon = renderExternalIcon && (
    <ExternalLinkIcon
      aria-hidden
      className={styles.icon}
      data-testid="external-icon"
    />
  );

  return (
    <Link
      className={className}
      onClick={onClick}
      pointer={pointer}
      data-testid={dataTestId}
    >
      {iconPosition === 'left' && icon}
      <span>{text}</span>
      {iconPosition === 'right' && icon}
    </Link>
  );
};
